const accessCodes = {
  pitang: ['PITANGTEST'],
  default: [
    'TESTAM',
    'TESTAXA',
    'DEMOCODE',
    'AA2022',
    'UK0222',
    'EU0122',
    'FR0122',
    'BE0122',
    'UK0320',
    'US1020',
    'CHUBB3220',
    'US0223',
    'US0323',
    'US0423',
    'US0523',
    'US0623',
    'US0723',
    'US0823',
    'US0123',
    'US0720',
    'BE0124',
    'UK0124',
    'UK0224',
    'EU0121',
    'FR0221',
    'UK0524',
    'ES0125',
  ],
};

export default (accessCode) => {
  const type = localStorage.getItem('theme') || 'default';

  return accessCodes[type].includes(accessCode?.toUpperCase()) ? true : false;
};
